import React, { useState } from "react"
import Layout from "../../components/Layout/LayoutNewYork"
import PageHeader from "../../components/shared/PageHeader"
import styled from "styled-components";
import ReactPlayer from "react-player"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { media } from 'utils/Media'
import { Container } from "reactstrap";

import HeaderShape from "../../images/HomePage/header-shape.svg"
import playIcon from '../../images/icons/btn-play.svg'
import { graphql } from "gatsby";

import StyledButton from "components/shared/StyledButton";
import { Helmet } from "react-helmet";



const TrailerPageStyles = styled.div`

`

const Content = styled.div`
    position: relative;
    text-align: center;
    
    @media ${media.lg} {
        margin: 2rem auto 0;
    }
`

const ColouredShape = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media ${media.md} {
        background: url("${HeaderShape}") center center no-repeat;
        background-size: 100%;
        height: 100px;
    }
    
    h3 {
        text-align: center;
        width: 100%;
        
        @media ${media.md} {
            width: 500px;
            font-size: 1.2rem;
        }
        
        @media ${media.lg} {
             width: 100%;
             max-width: 680px;
             font-size: 1.85rem;
         }
    }
    .font-override {
        @media ${media.lg} {
             width: 100%;
             max-width: 680px;
             font-size: 1.5rem;
         }
    }
`


const TrailerPage = ({ data }) => {
    const [playing, setPlaying] = useState(false)
	return(
		<Layout
			title="Trailer | New York | Back to the Future the Musical"
			description="Take an electrifying ride back in time with Back to the Future The Musical, Now playing on Broadway at the Winter Garden Theatre in New York."
			booking="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web"
		>
            <Helmet>
            <script
              id="satisfiScript"
              src="https://chat.satis.fi/popup/embedder?popupId=17903"
            ></script>
          </Helmet>
			<PageHeader city="new-york" title="Trailer" />
            <TrailerPageStyles>
                <Content>
                    <Container className="py-3 py-lg-5">
                        <ColouredShape>
                            {/* <h3 className="mb-3 mb-md-0 pt-3 pt-xl-0">GET TICKETS</h3> */}
                            <StyledButton target="_blank" rel=" noopener" href="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web" >
                    GET TICKETS
                </StyledButton>
                        </ColouredShape>

                        <div className="video-wrapper my-2">
                            <div className={`posterImage ${playing ? "playing" : ""}`}
                                tabIndex={0} 
                                role="button"
                                aria-label="button"
                                onClick={() => setPlaying(!playing)}
                                onKeyDown={() => setPlaying(!playing)}
                            >
                                <GatsbyImage image={getImage(data.greatScottVideo)}

                                    alt=""
                                    title=""
                                    className="img-fluid"
                                />
                                <img src={playIcon} alt="" title="" className="play-icon"/>
                            </div>
                            <ReactPlayer className="video-frame w-100"
                                        url={`https://youtu.be/GnwZ3Pkfd7s?rel=0&amp;autoplay=0&modestbranding=1`}
                                        // playing={this.state.playing}
                                        controls={true}/>
                        </div>
                        
                    </Container>
                </Content>
            </TrailerPageStyles>
		</Layout>
	)
}

export default TrailerPage


export const TrailerQuery = graphql`
    query TrailerQuery {
        
                greatScottVideo: file(relativePath: { eq: "new-york/BttF-Grab-2.jpg" }) {
                    childImageSharp {
                             gatsbyImageData(width: 1200)
                    }
                }
            
    }
`