import React, {Component} from "react"
import {graphql, StaticQuery} from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import {media} from "utils/Media"
import styled, {css} from "styled-components"
import ContainerMax from "components/shared/ContainerMax"

const ContainerStyled = styled.div`
  background-color: black;

  h1 {
    text-transform: uppercase;
    text-align: center;
    padding: 1rem 0;
    color: white;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;

  .delorean {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    max-width: 1200px;
    -webkit-animation: ease-in-out;
    -webkit-animation-name: fromLeft;
    -webkit-animation-duration: 2s;
    bottom: 0;
    width: 100%;

    @media ${media.sm} {
      width: 500px;
    }

    @media ${media.md} {
      width: 100%;
    }

    @media ${media.lg} {
      width: 68%;
    }

  }

  .imgNew {
    position: absolute;
    width: 1920px;
    left: 50%;
    top: 0;
    transform: translateX(-50%);

    @media ${media.lg} {
      position: relative;
      width: 100%;
      top: initial;
      left: initial;
      transform: none;
    }
  }

  ${props => props.fix && css`
    height: 260px;

    @media (min-width: 400px) {
      height: 320px;
    }

    @media ${media.sm} {
      height: 350px;
    }

    @media ${media.lg} {
      height: initial;
    }

    .logo {
      top: 20px !important;
      transform: translateX(-50%) !important;
      width: 240px !important;
      max-width: 90%;
    }
  `}
  .logo {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    z-index: 2;

    @media ${media.md} {
      width: 70%;
      max-width: 300px;
    }

    @media ${media.lg} {
      display: none;
    }
  }

  @keyframes fromLeft {
    0% {
      left: -100%;
    }
    100% {
      left: 50%;
    }
  }
`

const ImgStyled = styled(GatsbyImage)`
  max-width: 1740px;
  margin: 0 auto;
`

const ImgNewStyled = styled(GatsbyImage)`
  max-width: 1740px;
  margin: 0 auto;
`

const Title = styled.div`
  position: relative;
  overflow: hidden;

  h1 {
    position: relative;
    z-index: 2;
  }

  .lines {
    position: absolute;
    z-index: 1;
    background-color: ${props => props.theme.colors.red};
    height: 2px;
    width: 100vw;
    top: 37%;
    transition: all .4s ease;
    left: 0;

    @media ${media.sm} {
      top: 42%;
    }

    @media ${media.xl} {
      bottom: 45px;
      top: initial;
    }

    &:after,
    &:before {
      content: "";
      position: absolute;
      right: 0;
      z-index: 9;
      background-color: ${props => props.theme.colors.orange};
      height: 2px;
      width: 98%;
      top: .4rem;
    }

    &:before {
      width: 96%;
      top: .8rem;
      background-color: ${props => props.theme.colors.yellow};
    }
  }
`

class PageHeader extends Component {
  render() {
    console.log(this.props)
    return (
      <ContainerStyled fluid>
        <ContainerMax>
          {this.props.newBg &&
            <ImageWrapper fix>
              <div className="logo">
                <GatsbyImage image={getImage(this.props.data.logo)}
                
                             alt=""
                             title=""
                             className="img-fluid"
                />
              </div>
              <div className="imgNew">
                <ImgNewStyled
                  image={getImage(this.props.data.imageNew)}
                  alt={this.props.title}/>
              </div>
              <div className="delorean">
                <GatsbyImage image={getImage(this.props.data.delorean)}
                
                             alt=""
                             title=""
                             className="img-fluid"
                />
              </div>
            </ImageWrapper>
          }
          {this.props.imageLg && this.props.imageSm && this.props.imageXs &&
            <ImageWrapper>
              <GatsbyImage image={getImage(this.props.imageLg)}
                           alt=""
                           title=""
                           className="d-none d-lg-block"
              />
              <GatsbyImage image={getImage(this.props.imageXs)}
              
                           alt=""
                           title=""
                           className="img-fluid d-block d-sm-none"
              />
              <GatsbyImage image={getImage(this.props.imageSm)}
              
                           alt=""
                           title=""
                           className="img-fluid d-none d-sm-block d-lg-none"
              />
            </ImageWrapper>
          }
          {typeof this.props.newBg === "undefined" && typeof this.props.imageLg === "undefined" && typeof this.props.city === "undefined" &&
            <ImageWrapper>
              <GatsbyImage image={getImage(this.props.data.logo)}
              
                           alt=""
                           title=""
                           className="img-fluid logo"
              />
              <ImgStyled
                image={getImage(this.props.data.imageLg)}
                alt={this.props.title}
                className="d-none d-lg-block"
              />
              <GatsbyImage image={getImage(this.props.data.imageXs)}
              
                           alt=""
                           title=""
                           className="img-fluid d-block d-sm-none"
              />
              <GatsbyImage image={getImage(this.props.data.imageSm)}
              
                           alt=""
                           title=""
                           className="img-fluid d-none d-sm-block d-lg-none"
              />
            </ImageWrapper>
          }
          
          {typeof this.props.newBg === "undefined" && this.props.city === "new-york" &&
            <ImageWrapper>
              <ImgStyled
                image={getImage(this.props.data.imageLgNY)}
                alt={this.props.title}
                className="d-none d-lg-block"
              />
              <GatsbyImage image={getImage(this.props.data.imageXsNY)}
              
                           alt=""
                           title=""
                           className="img-fluid d-block d-sm-none"
              />
              <GatsbyImage image={getImage(this.props.data.imageSmNY)}
              
                           alt=""
                           title=""
                           className="img-fluid d-none d-sm-block d-lg-none"
              />
            </ImageWrapper>
          }
          {typeof this.props.newBg === "undefined" && this.props.city === "us-tour" &&
            <ImageWrapper>
              <ImgStyled
                image={getImage(this.props.data.imageLgUS)}
                alt={this.props.title}
                className="d-none d-lg-block"
              />
              <GatsbyImage image={getImage(this.props.data.imageXsUS)}
              
                           alt=""
                           title=""
                           className="img-fluid d-block d-sm-none"
              />
              <GatsbyImage image={getImage(this.props.data.imageSmUS)}
              
                           alt=""
                           title=""
                           className="img-fluid d-none d-sm-block d-lg-none"
              />
            </ImageWrapper>
          }
          {this.props.title !== "Cast & Creative" &&
            <Title>
              <h1>{this.props.title}</h1>
              <div className="lines"/>
            </Title>
          }
        </ContainerMax>
      
      </ContainerStyled>
    
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
            query {
                imageXs: file(relativePath: { eq: "bg-subpage-header-576-new3.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 575,
                            quality: 100
                        )
                    }
                }
                imageSm: file(relativePath: { eq: "bg-subpage-header-991-new3.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 991,
                            quality: 100
                        )
                    }
                }
                imageLg: file(relativePath: { eq: "BttF-Header-4.jpg" }) {
                    childImageSharp {
                         gatsbyImageData(
                            width: 1740,
                            quality: 100
                        )
                    }
                }
                imageXsNY: file(relativePath: { eq: "new-york/576BTTF.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 575,
                            quality: 100
                        )
                    }
                }
                imageSmNY: file(relativePath: { eq: "new-york/991BTTF.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 991,
                            quality: 100
                        )
                    }
                }
                imageLgNY: file(relativePath: { eq: "new-york/1920BTTF.jpg" }) {
                    childImageSharp {
                         gatsbyImageData(
                            width: 1740,
                            quality: 100
                        )
                    }
                }
                imageXsUS: file(relativePath: { eq: "us-tour/576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 575,
                            quality: 100
                        )
                    }
                }
                imageSmUS: file(relativePath: { eq: "us-tour/991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 991,
                            quality: 100
                        )
                    }
                }
                imageLgUS: file(relativePath: { eq: "us-tour/1920.jpg" }) {
                    childImageSharp {
                         gatsbyImageData(
                            width: 1740,
                            quality: 100
                        )
                    }
                }
                imageNew: file(relativePath: { eq: "CastAlbum/new-bg-subpage.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1740,
                            quality: 100
                        )
                    }
                }
                delorean: file(relativePath: { eq: "CastAlbum/delorean.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1200,
                            quality: 100
                        )
                    }
                }
                logo: file(relativePath: { eq: "BTTF-LOGO-2020-NEW.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 600,
                            quality: 100
                        )
                    }
                }
            }
		`}
    render={data => (
      <PageHeader
        {...props}
        data={data}/>
    )}
  />
)
